body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-slider-mark-text {
    max-width: 75px !important;
    font-size: x-small;
}

.ant-page-header {
    padding: 16px 50px !important;
}

.penta-container-content {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    margin-left: 50px;
    margin-right: 50px;
    padding: 2%;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    /*height: calc(100vh - 70px - 64px - 48px);
    margin-top: calc(24px + 64px);*/
    overflow: scroll;
}

.penta-header {
    background-color: transparent !important;
}

.fixed-widgets {
    position: fixed;
    right: 50px;
    top: 12px;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
}

.ant-slider-with-marks {
    margin-bottom: 55px !important;
    width: 90% !important;
    left: 5% !important;
}


/* responsive, form small screens, use 13px font size */

@media (max-width: 479px) {
    .ant-layout-header {
        padding-left: 3vw !important;
        padding-right: 3vw !important;
    }
    .fixed-widgets {
        right: 3vw !important;
    }
    .ant-page-header {
        padding-left: 3vw !important;
        padding-right: 3vw !important;
    }
    .penta-container-content {
        margin-left: 3vw !important;
        margin-right: 3vw !important;
    }
}

.ant-pro-steps-form-step {
    margin-bottom: 32px;
}

.penta-divider-horizontal {
    font-size: 8px !important;
}

.penta-card {
    background-color: transparent !important;
}

.penta-card:hover {
    background-color: #f0f2f5 !important;
}